import React from 'react'

import Layout from '../../layouts'
import MetaHeader from '../../components/MetaHeader'
import {
  Card,
  CustomButton,
  Image,
  Section,
  Text,
  Title,
} from '../../components/future-capital-executive-program'
import { colors } from '../../components/theme'

// IMAGES
import HeroImage from '../../images/future-capital-executive-program/hero.jpg'
import ThoughtIcon from '../../images/future-capital-executive-program/thought-icon.svg'
import GaugeIcon from '../../images/future-capital-executive-program/gauge-icon.svg'
import MicrosoftLogo from '../../images/future-capital-executive-program/microsoft-logo.png'
import DTSLogo from '../../images/future-capital-executive-program/dts-logo.png'
import SFULogo from '../../images/future-capital-executive-program/sfu-logo.png'

import Stock1 from '../../images/future-capital-executive-program/attend-live-sessions.png'
import Stock2 from '../../images/future-capital-executive-program/hands-on.png'
import Stock3 from '../../images/future-capital-executive-program/learning-laptop.png'

const FutureCapitalExecutiveProgram = () => {
  return (
    <Layout>
      <MetaHeader
        title="Introducing the Future Capital Executive Program"
        description="Harness the power of early stage investing as a tool for corporate innovation and professional development."
        image={HeroImage}
      />
      <Section
        width={[1, 1, 3 / 4, 900]}
        px={[3, 4, 0]}
        paddingTop={[3, 5]}
        my={0}
        mx="auto">
        <Section>
          <Title
            color={colors.secondaryText}
            fontSize={[43, 60]}
            fontFamily="futura-pt"
            fontWeight={500}
            mt={[4]}
            mb={4}>
            Introducting the Future Capital Executive Program
          </Title>
        </Section>
        <img src={HeroImage} alt="future capital executive program" />
        <Section width={[1, 1]}>
          <Text fontSize={5} mt={[4]} color={colors.primary} textAlign="center">
            Coming in Spring 2020
          </Text>
          <Text fontSize={[32]} mt={[4]} mb={[5]} textAlign="center">
            Harness the power of early stage investing as a tool for corporate
            innovation and professional development.
          </Text>
          <Section mx="auto" textAlign="center">
            <CustomButton
              width={[1, 1 / 4]}
              to="future-capital-executive-program/sign-up">
              GET UPDATES
            </CustomButton>
          </Section>
        </Section>
      </Section>
      <Section bg="#E2F4F0" my={5}>
        <Section
          width={[1, 1, 3 / 4, 900]}
          px={[3, 4, 0]}
          py={[3, 5]}
          my={0}
          mx="auto">
          <Title
            color={colors.primaryText}
            fontSize={[31]}
            fontFamily="Lato"
            mt={[4]}
            mb={5}>
            What you’ll learn
          </Title>
          <Section
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            mb={4}>
            <Card
              width={[1, 1, 31 / 64]}
              mb={[3, 3]}
              px={3}
              py={5}
              bg={colors.white}>
              <img src={ThoughtIcon} alt="thought icon" />
              <Text fontSize={22} color="#2C4F58" mt={4}>
                Understand the investment process
              </Text>
              <Text fontSize={16} lineHeight="1.5" mt={2} color="#4F4F4F">
                Get an extensive overview of the early-stage investing cycle
                with a focus on opportunity assessment, valuation, deal
                structuring and early-stage investment process.
              </Text>
            </Card>
            <Card
              width={[1, 1, 31 / 64]}
              mb={[3, 3]}
              px={3}
              py={5}
              bg={colors.white}>
              <img src={GaugeIcon} alt="gauge icon" />
              <Text fontSize={22} color="#2C4F58" mt={4}>
                Gain valuable practical skills
              </Text>
              <Text fontSize={16} lineHeight="1.5" mt={2} color="#4F4F4F">
                Understand the mechanics behind investment and gain skills
                around: the due dilligence process, anatomy of a term sheet, how
                to read a cap table, and and understand how entrepreneurs can
                best optimize their returns.
              </Text>
            </Card>
            <Card
              width={[1, 1, 31 / 64]}
              mb={[3, 3]}
              px={3}
              py={5}
              bg={colors.white}>
              <img src={ThoughtIcon} alt="thought icon" />
              <Text fontSize={22} color="#2C4F58" mt={4}>
                Innovation through venture creation
              </Text>
              <Text fontSize={16} lineHeight="1.5" mt={2} color="#4F4F4F">
                Learn to identify opportunities by deveping an understanding of
                what makes a startup fundable. By understanding how to bridge
                the gap between techonology/opportunity and value.
              </Text>
            </Card>
            <Card
              width={[1, 1, 31 / 64]}
              mb={[3, 3]}
              px={3}
              py={5}
              bg={colors.white}>
              <img src={GaugeIcon} alt="gauge icon" />
              <Text fontSize={22} color="#2C4F58" mt={4}>
                Identify, capture and drive opportunities
              </Text>
              <Text fontSize={16} lineHeight="1.5" mt={2} color="#4F4F4F">
                Develop a framework and set of criteria that will enable you to
                indetify strong opportunities. Learn new methods to search for
                new ideas, test hypotheses, learn methods to search for
                opportunities and capture value from your deal flow.
              </Text>
            </Card>
          </Section>
        </Section>
      </Section>
      <Section
        width={[1, 1, 3 / 4, 900]}
        px={[3, 4, 0]}
        py={[3, 5]}
        my={0}
        mx="auto">
        <Title
          color={colors.primaryText}
          fontSize={[31]}
          fontFamily="Lato"
          mb={4}>
          Our Structure
        </Title>
        <Section
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          mb={[4, 5]}>
          <Section
            width={[1, 3 / 10]}
            mb={[4, 0]}
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Title
              color="#2C4F58"
              fontSize={[22]}
              fontWeight={600}
              fontFamily="Lato"
              mb={3}>
              Attend live sessions
            </Title>
            <Text fontSize={[16]} lineHeight="1.5">
              Learn a new skillset through engaging live sessions, and apply
              learnings throughout the course by working collaboratively with
              particinpants in an online setting.
            </Text>
          </Section>
          <Image width={[1, 6 / 10]} src={Stock1} alt="attend live sessions" />
        </Section>
        <Section
          display="flex"
          flexDirection="row-reverse"
          flexWrap="wrap"
          justifyContent="space-between"
          mb={[4, 5]}>
          <Section
            width={[1, 3 / 10]}
            mb={[4, 0]}
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Title
              color="#2C4F58"
              fontSize={[22]}
              fontWeight={600}
              fontFamily="Lato"
              mb={3}>
              Meet sector experts
            </Title>
            <Text fontSize={[16]} lineHeight="1.5">
              Leverage the deep, collective expertise of the Female Funders’
              network. Receive tips, techniques and feedback from mentors, peers
              and subject matter experts.
            </Text>
          </Section>
          <Image width={[1, 6 / 10]} src={Stock3} alt="learning laptop" />
        </Section>
        <Section
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          mb={[4, 5]}>
          <Section
            width={[1, 3 / 10]}
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Title
              color="#2C4F58"
              fontSize={[22]}
              fontWeight={600}
              fontFamily="Lato"
              mb={3}>
              Get hands on
            </Title>
            <Text fontSize={[16]} lineHeight="1.5">
              Learn a new skillset through engaging live sessions, and apply
              learnings throughout the course by working collaboratively with
              particinpants in an online setting.
            </Text>
          </Section>
          <Image width={[1, 6 / 10]} src={Stock2} alt="hands on" />
        </Section>
      </Section>
      <Section
        width={[1, 1, 3 / 4, 900]}
        px={[3, 4, 0]}
        py={[3, 5]}
        my={0}
        mx="auto">
        <Title
          color={colors.primaryText}
          fontSize={[31]}
          fontFamily="Lato"
          mb={4}
          textAlign="center">
          Partners & Affiliates
        </Title>
        <Section
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignContent="center"
          mb={[3, 4]}>
          <Image
            width={[200]}
            mb={[5, 0]}
            mx="auto"
            src={MicrosoftLogo}
            alt="logo microsoft"
          />
          <Image
            width={[200]}
            mb={[5, 0]}
            mx="auto"
            src={DTSLogo}
            alt="logo digital technology supercluster"
          />
          <Image
            width={[200]}
            mx="auto"
            src={SFULogo}
            alt="logo simon fraser university"
          />
        </Section>
      </Section>
    </Layout>
  )
}

export default FutureCapitalExecutiveProgram
